<template>
  <div style="display: flex;width: 100%">
    <div style="width: 140px;">支付成功后</div>
    <VSelect :options="options" :value="value" @input="input" />
    <div style="width: 140px;">天内发货</div>
  </div>
</template>

<script>
import VSelect from "@/components/form/VSelect.vue";
export default {
  name: 'ShiduanModal',
  components: { VSelect },
  props: ['value'],
  data() {
    return {
      options: [
        { label: '2天', value: 2 },
        { label: '3天', value: 3 },
        { label: '4天', value: 4 },
        { label: '5天', value: 5 },
        { label: '6天', value: 6 },
        { label: '7天', value: 7 },
        { label: '8天', value: 8 },
        { label: '9天', value: 9 },
        { label: '10天', value: 10 },
        { label: '11天', value: 11 },
        { label: '12天', value: 12 },
        { label: '13天', value: 13 },
        { label: '14天', value: 14 },
        { label: '15天', value: 15 },
      ],
    };
  },
  methods: {
    input(e) {
      this.$emit('input', e);
      this.$emit('on-change', e);
    },
  },
};
</script>

<style scoped lang="less"></style>
