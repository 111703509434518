<template>
  <Select
    v-bind="$attrs"
    :model-value="props.value"
    placeholder="请选择"
    @on-change="input"
    @input="input"
  >
    <Option v-for="it in props.options" :key="it.value" :value="it.value">{{ it.label }}</Option>
  </Select>
</template>

<script setup lang="ts">
const emits = defineEmits(['input', 'change']);
const props = defineProps(['value', 'options']);
import { useAttrs } from 'vue';
const $attrs = useAttrs();
const input = (e) => {
  console.log(e);
  emits('input', e);
  emits('change', e);
};
</script>

<style scoped lang="less"></style>
