import type { RouteRecordRaw, onBeforeRouteLeave, useRoute } from 'vue-router';

const routes: RouteRecordRaw[] = [
  // {
  //   path: '/',
  //   // component: () => import('@/views/myHome/index.vue'),
  //   redirect: '/layout',
  // },
  {
    path: '/',
    component: () => import('@/views/layout/index.vue'),
    redirect: '/watermark/custom',
    beforeEnter: (to, from, next) => {
      const fragment = location.href.split('#')[1];
      const accessTokenMatch = fragment.match(/accessToken=([^&]+)/);
      const ownerIdMatch = fragment.match(/ownerId=([^&]+)/);
      if (accessTokenMatch) {
        const accessToken = accessTokenMatch[1];
        const ownerId = ownerIdMatch[1];
        sessionStorage.setItem('accessToken', accessToken);
        sessionStorage.setItem('ownerId', ownerId);
        next();
      } else {
        const token = sessionStorage.getItem('accessToken');
        const ownerId = sessionStorage.getItem('ownerId');
        if (token && ownerId) {
          next();
          return;
        }
        alert('未正确获取到用户信息，即将重定向至拼多多商家首页')
        location.href = 'https://mms.pinduoduo.com/login/'
        next();
      }
    },
    children: [
      {
        name: 'watermark',
        path: 'watermark',
        component: () => import('@/views/watermark/index.vue'),
        redirect: '/watermark/system',
        children: [
          {
            name: 'system',
            path: 'system',
            component: () => import('@/views/watermark/components/systemTemplate.vue'),
          },
          {
            name: 'custom',
            path: 'custom',
            component: () => import('@/views/watermark/components/customTemplate.vue'),
          },
          {
            name: 'toufang',
            path: 'toufang',
            component: () => import('@/views/watermark/components/myToufang.vue'),
          },
          {
            name: 'toufangDetail',
            path: 'toufangDetail',
            component: () => import('@/views/watermark/components/toufangDetail.vue'),
          },
        ],
      },
      {
        name: 'goods',
        path: 'goods',
        component: () => import('@/views/goods/index.vue'),
      },
      {
        name: 'import',
        path: 'import',
        component: () => import('@/views/import/index.vue'),
      },
      {
        name: 'whole-shop',
        path: 'whole-shop',
        component: () => import('@/views/wholeShop/index.vue'),
      },
      {
        name: 'whole-shop-nocheck',
        path: 'whole-shop-nocheck',
        component: () => import('@/views/wholeShopNocheck/index.vue'),
      },
      {
        name: 'export',
        path: 'export',
        component: () => import('@/views/export/index.vue'),
      },
      {
        name: 'mall',
        path: 'mall',
        component: () => import('@/views/mall/index.vue'),
      },
      {
        name: 'timing',
        path: 'timing',
        component: () => import('@/views/timing/index.vue'),
        redirect: '/timing/timing',
        children: [
          {
            name: 'timing',
            path: 'timing',
            component: () => import('@/views/timing/components/timing.vue'),
          },
          {
            name: 'daily',
            path: 'daily',
            component: () => import('@/views/timing/components/daily.vue'),
          },
          {
            name: 'weekly',
            path: 'weekly',
            component: () => import('@/views/timing/components/weekly.vue'),
          },
        ],
      },
      {
        name: 'draft',
        path: 'draft',
        component: () => import('@/views/draft/index.vue'),
      },
    ],
  },

  // {
  //   path: '/dome',
  //   component: () => import('@/views/home/index.vue'),
  // },
];

export default routes;
